import React from 'react'
import Layout from '../../components/layout'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const Faq6 = () => {
  return (
    <>
      <Layout>
        <Container>
          <Row>
            <div className="back-button mb-4 mt-3">
              <Link to="/faq/">&larr; Back</Link>
            </div>
            <Col sm="12">
              <div className="mb-5">
                <StaticImage
                  src="../../images/blue-background-man-1200x600.jpg"
                  alt="Man"
                  placeholder="none"
                  layout="fullWidth"
                />
                <h2 className="mt-3">
                  How long do Lumineers<sup>&reg;</sup> last?
                </h2>
                <p>
                  DenMat has been fabricating dental veneers since 1984 and
                  under the Lumineers<sup>&reg;</sup> brand since 2004. Millions
                  of Lumineers have been placed by thousands of dentists. While
                  no dental veneer is unbreakable when exposed to unusual or
                  extreme forces, clinical testing has shown that Lumineers can
                  last many years with proper dental care.
                </p>
              </div>
              <div className="mb-5 text-center">
                <Link to="../../about/" className="intro-button mb-5">
                  Learn More
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default Faq6
